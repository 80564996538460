import { IWindIncluded } from "@/store/modules/quote/types";
import { stripAgencyCode } from "./generalHelpers";
import { allRoofMaterialOptions } from "./selectOptions";

export function validateAtlasRoofYear(
  limit: number,
  value: number,
  roofMaterial: string
) {
  if (value && !IsYear(value.toString())) {
    return {
      success: false,
      message: "Valid Roof Installation Year is required",
      type: "invalid",
      reason: ""
    };
  }

  const today = new Date();
  const yearNow = today.getFullYear();
  const yearDifference = yearNow - value;
  if (yearDifference > limit && roofMaterial != "8") {
    return {
      success: false,
      message: "Call Underwriter for Approval",
      type: "call",
      reason: `Roof age is more than ${limit} years`
    };
  }
  return {
    success: true,
    message: "",
    type: "valid",
    reason: ""
  };
}

export function IsYear(year: string) {
  if (year.length != 4) {
    return false;
  } else {
    const fs = year.substring(0, 2);
    if (fs == "19" || fs == "20") {
      return true;
    }
  }
}

export function validateColonialRoofYear(options: {
  yearOfRoof: number;
  isSeacoast: boolean;
  roofMaterial: string;
  agentCode: string;
  yearBuild: number;
  formType?: string;
  windIncluded?: IWindIncluded;
}) {
  const {
    yearOfRoof,
    isSeacoast,
    roofMaterial = "",
    agentCode = "",
    windIncluded,
    yearBuild,
    formType
  } = options;

  const currentYear = new Date().getFullYear();
  const roofAge = currentYear - yearOfRoof;
  const dwellingYear = currentYear - yearBuild;
  const dwellingAgeLimit = 30;
  const roofAgeLimit = 15;

  if (yearOfRoof && !IsYear(yearOfRoof.toString())) {
    return {
      success: false,
      message: "Valid Roof Installation Year is required",
      type: "invalid",
      reason: ""
    };
  }

  const callUnderwriterResponse = {
    success: false,
    message: "Call Underwriter for Approval",
    reason: "",
    type: "call"
  };
  const coreAgentCode = stripAgencyCode(agentCode);
  const name = allRoofMaterialOptions.find(
    option => option.value === roofMaterial
  );
  const roofError = `${name?.label} Roof over 10 years not allowed. Roof age ${roofAge} years`;

  function check1() {
    return (
      windIncluded === "no" &&
      roofAge > roofAgeLimit &&
      formType === "AP" &&
      dwellingYear > dwellingAgeLimit
    );
  }

  function check2() {
    return (
      roofAge > roofAgeLimit &&
      (isSpecialAgent(coreAgentCode) ||
        (!isSpecialAgent(coreAgentCode) && roofMaterial != "6"))
    );
  }

  function check3() {
    return (
      !isSpecialAgent(coreAgentCode) && roofMaterial == "6" && roofAge > 10
    );
  }

  if (isSeacoast) {
    if (check1() || check2() || check3()) {
      callUnderwriterResponse.message = roofError;
      return callUnderwriterResponse;
    }
  } else {
    if (roofAge > roofAgeLimit && roofMaterial != "8") {
      callUnderwriterResponse.reason = roofError;
      return callUnderwriterResponse;
    }
  }

  return {
    success: true,
    message: "",
    type: "valid",
    reason: ""
  };
}

function isSpecialAgent(coreAgentCode: string): boolean {
  return ["altr", "sgim", "gsmi-rock", "setx"].includes(
    coreAgentCode.toLowerCase()
  );
}
