<template>
  <div class="text-red-500">
    <ul class="list-disc">
      <li v-for="(message, index) in messages" :key="index">
        {{ message }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      default: [],
      required: true
    }
  }
};
</script>
